<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      :filter-names="['search']"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:role="{ item }">
        <div>{{ item.role.join(", ") }}</div>
      </template>

      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('users.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('users.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <template v-slot:addFilters>
        <template v-if="authUser.isAdmin">
          <v-card>
            <v-autocomplete
              v-model="customFilter.user_type"
              filled
              dense
              clearable
              :items="userTypeList"
              item-text="name"
              item-value="id"
              :label="$t('user_type')"
              no-filter
              @change="onChangeUserType"
            />
          </v-card>
          <v-card>
            <v-autocomplete
              v-model="customFilter.role_name"
              filled
              dense
              clearable
              :items="
                authUser.isAdmin
                  ? customFilter.user_type
                    ? listRoles
                    : []
                  : listRoles
              "
              item-text="name"
              item-value="name"
              :label="$t('role')"
              no-filter
              @change="fetchData"
            />
          </v-card>
        </template>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-user
            v-if="$admin.can('users.create')"
            :filter-options="{ ...options, ...customFilter }"
          />
          <edit-user
            v-if="$admin.can('users.edit') && editModal"
            :is-visible-dialog="editModal"
            :filter-options="{ ...options, ...customFilter }"
            :toggle-edit="toggleEditModal"
            :user="selectedRow"
          />
          <confirmation-modal
            v-if="$admin.can('users.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateUser: () => import("./dialogs/CreateUser.vue"),
    EditUser: () => import("./dialogs/EditUser.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      customFilter: {},
      //
      userTypeList: [
        {
          id: "orko",
          name: "ORKO",
        },
        {
          id: "product",
          name: "TENANT",
        },
      ],
      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
          width: "15%",
        },
        {
          text: this.$t("user_type"),
          align: "left",
          sortable: false,
          value: "user_type_in_listing",
          width: "15%",
          showAdmin: true,
        },
        {
          text: this.$t("email"),
          align: "left",
          sortable: false,
          value: "email",
          width: "15%",
        },
        {
          text: this.$t("role"),
          align: "left",
          sortable: false,
          value: "role",
          width: "15%",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
          width: "15%",
        },
        ...(this.$admin.hasAccessTo("users.delete") ||
        this.$admin.hasAccessTo("users.edit")
          ? [
              {
                text: this.$t("actions"),
                align: "left",
                sortable: false,
                value: "actions",
                width: "15%",
              },
            ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "users/getIsLoadingUsers",
      meta: "users/getUsersMeta",
      list: "users/getUsersList",
      listRoles: "roles/listRoles",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {
    // for type
    this.$store.dispatch("users/setLoadingValue", true);
    if (this.$route?.query?.user_type) {
      this.customFilter.user_type = this.$route.query.user_type;
      this.onChangeUserType(this.customFilter.user_type);
    }
    if (this.$route?.query?.role_name) {
      this.customFilter.role_name = this.$route.query.role_name;
    }
  },
  beforeDestroy() {
    const params = {
      name: "users",
      filters: {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.options.search || undefined,
        ...this.customFilter,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      const params = { ...this.options, ...this.customFilter };
      this.options.page = 1;
      await this.$store.dispatch("users/list", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("users/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    //
    async onChangeUserType(value) {
      delete this.customFilter.role_name;
      if (this.authUser.isAdmin) {
        if (value) {
          this.$store.dispatch("roles/list", { type: value });
        }
        this.fetchData();
      }
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined).v-input--dense .v-select__slot > input
  font-size: 0.875rem !important
</style>
